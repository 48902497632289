<template>
  <div class="pos_wrap">
    <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler">
      <!--      <bm-driving start="天安门" end="百度大厦" @searchcomplete="handleSearchComplete" :panel="false" :autoViewport="true"></bm-driving>-->
      <!--      <bml-lushu-->
      <!--          @stop="reset"-->
      <!--          :path="path"-->
      <!--          :icon="icon"-->
      <!--          :play="play"-->
      <!--          :rotation="true">-->
      <!--      </bml-lushu>-->
      <bm-control :offset="{ width: 20, height: 20 }">
        <div class="pos_control_panel">
          <el-select v-model="activeFence" placeholder="请选择设备" @change="selectEquip" style="margin-right: 10px">
            <el-option v-for="item in equipList" :key="item.entityNumber + item.entityFactory" :label="
              formateEquipType(item.entityType) +
              ' | ' +
              item.entityNumber +
              ' | ' +
              formateEquipFactory(item.entityFactory)
            " :value="item.entityNumber + '|' + item.entityFactory">
            </el-option>
          </el-select>
          <el-checkbox v-model="fenceVisible">显示围栏</el-checkbox>
        </div>
      </bm-control>
      <bm-marker :position="center" :icon="iconCom" @click="modelClick" :rotation="posInfo.direction" />
      <bm-info-window :position="center" title="" :show="true" @close="showInfoWindow = false" style="width: 240px"
        v-if="showInfoWindow">
        <p class="w_desc">{{ posInfo.carNumber }}-{{ posInfo.carName }}</p>
        <p class="w_desc">
          {{ posInfo.locDesc }}
        </p>
        <div class="w_item">
          <span>坐标：</span>
          <span>经度：{{ posInfo.longitude }} , 纬度：{{ posInfo.latitude }}</span>
        </div>
        <div class="w_item">
          <span>速度：</span>
          <span>{{ posInfo.speed }}km/h</span>
        </div>
        <div class="w_item">
          <span>定位时间：</span>
          <span>{{ timeFormate(posInfo.locTime) }}</span>
        </div>
        <div class="w_item">
          <span>服务器时间：</span>
          <span>{{ posInfo.heartTime }}</span>
        </div>
        <div class="w_item">
          <span>设备状态：</span>
          <span>{{ posInfo.offline == 1 ? "离线" : "在线" }}</span>
        </div>
        <div class="w_item">
          <span>设备号：</span>
          <span>{{ posInfo.deviceId }}</span>
        </div>
        <div v-if="false" class="w_item">
          <span>设备名称：</span>
          <span>{{ posInfo.deviceName }}</span>
        </div>
        <div class="w_item">
          <span>设备类型：</span>
          <span>{{ formateEquipType(posInfo.deviceType) }}</span>
        </div>
        <div class="w_item">
          <span>设备厂家：</span>
          <span>{{ formateEquipFactory(posInfo.deviceFactory) }}</span>
        </div>
        <div class="w_item">
          <span>里程：</span>
          <span>{{ posInfo.mil }}</span>
        </div>
        <div class="w_item">
          <span>定位信号类型：</span>
          <span>{{
            signalTypeFormat(posInfo.signalType, posInfo.deviceFactory)
          }}</span>
        </div>
        <div class="w_item">
          <el-button class="label" type="text" icon="el-icon-aim" @click="
            toTrace(
              posInfo.deviceId,
              timeFormate(posInfo.locTime),
              posInfo.deviceFactory
            )
          ">轨迹</el-button>

          <el-button class="label" type="text" style="text-decoration: underline" @click="openAreaModal">封闭区域</el-button>
          <el-button class="label" type="text" @click="delAreaModal"
            style="color: #000000; text-decoration: underline">删除</el-button>
        </div>
      </bm-info-window>
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
      <bm-polygon :path="path" v-for="(path, $index) in polygonLayerList" :key="$index" fillColor="rgb(12,21,46)"
        stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2" />

      <bm-label :content="pos.name" v-for="(pos, $index) in labelPos" :key="$index + '_' + pos"
        :position="{ lng: pos.lng, lat: pos.lat }" :labelStyle="{
          color: 'white',
          fontSize: '18px',
          background: 'black',
          border: '',
        }" />

      <bm-polyline :path="posPath" stroke-color="#00ff37" :stroke-opacity="1" :stroke-weight="4" :editing="false" />
    </baidu-map>

    <!--      区域设置-->
    <el-dialog :visible.sync="areaVisible" v-if="areaVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>封闭区域</span>
        <i class="el-icon-close" style="font-size: 26px" @click="areaVisible = false" />
      </div>
      <div class="area_contain">
        <div class="contain-item">
          <!-- <div class="item-radio">
            <el-radio v-model="areaOption.type" label="1">圆形</el-radio>
          </div> -->
          <span style="margin-right: 10px">半径</span>
          <el-input-number v-model="areaOption.circleNum" :controls="false" :min="0" :max="2000"></el-input-number>
          <span style="margin-right: 10px">米</span>
          <span class="item-text">说明：最大1000米</span>
        </div>
        <!-- <div class="contain-item">
          <div class="item-radio">
            <el-radio v-model="areaOption.type" label="2">正方形</el-radio>
          </div>
          <span style="margin-right: 10px">半径</span>
          <el-input-number
            v-model="areaOption.squareNum"
            :controls="false"
            :min="0"
            :max="2000"
          ></el-input-number>
          <span class="item-text">说明：最大2000米</span>
        </div> -->
        <div class="contain-item j-c">
          <el-button type="warning" icon="el-icon-plus" class="ml20 mt10 searchBtn" @click="areaConfim">保存</el-button>
          <el-button type="primary" class="ml20 mt10 searchBtn" @click="areaVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { Loading, Message } from "element-ui";
import traceApi from "../api/trace";
import { formatTimeToStr } from "../api/date";
import monitorApi from "../api/monitor";
import fenceApi from "../api/fence";
import {
  formatFactory,
  equipTypeFormat,
  signalTypeFormat,
} from "../api/carview";
// import equipment from "../api/equipment";
export default {
  components: {
    BaiduMap,
    // BmlLushu
  },
  computed: {
    iconCom() {
      if (this.posInfo.offline == 1) {
        return this.icon_hei;
      } else {
        if (this.posInfo.speed > 120) {
          return this.icon_hong;
        } else {
          return this.icon_lan;
        }
      }
    },
  },
  data() {
    return {
      showInfoWindow: false,
      path: [],
      //黑色
      icon_hei: {
        url: require("../assets/images/car02.png"),
        size: { width: 17, height: 34 },
      },
      //红色
      icon_hong: {
        url: require("../assets/images/car06.png"),
        size: { width: 17, height: 34 },
      },
      //蓝色
      icon_lan: {
        url: require("../assets/images/car01.png"),
        size: { width: 17, height: 34 },
      },
      center: {},
      zoom: 3,
      locationLoading: null,
      posInfo: {},
      activeFence: "",
      equipList: [],
      fenceList: [],
      mapObj: null,
      fenceVisible: false,
      mapInstance: null,
      circleLayerList: [],
      polygonLayerList: [],
      posPath: [],
      labelPos: [],
      orderNumber1: "",
      carNumber1: "",
      equipNumber1: null,
      page1: "",
      equipFactory1: null,
      timer: null,
      isInit: false,
      areaVisible: false,
      areaOption: {
        type: "1",
        squareNum: 500,
        circleNum: 500,
        isShow: false,
        id: "",
      },
    };
  },
  props: {
    orderNumber: String,
    carNumber: String,
    city: String,
    page: String,
    equipNumber: String,
    equipFactory: String,
  },
  mounted() {
    this.locationLoading = Loading.service({
      text: "加载地理位置",
      background: "rgba(0, 0, 0, 0.8)",
    });
    if (this.orderNumber) {
      this.orderNumber1 = this.orderNumber || "";
    } else {
      this.orderNumber1 = this.$route.query.orderNumber || "";
    }
    if (this.carNumber) {
      this.carNumber1 = this.carNumber || "";
    } else {
      this.carNumber1 = this.$route.query.carNumber || "";
    }
    if (this.equipNumber) {
      this.equipNumber1 = this.equipNumber || "";
    } else {
      this.equipNumber1 = this.$route.query.equipNumber || "";
    }
    if (this.page) {
      this.page1 = this.page || "";
    } else {
      this.page1 = this.$route.query.page || "";
    }
    if (this.equipFactory) {
      this.equipFactory1 = this.equipFactory || "";
    } else {
      this.equipFactory1 = this.$route.query.equipFactory || "";
    }
    this.getTemporaryFence();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    fenceVisible(nv) {
      if (nv) {
        this.getFenceList(this.mapInstance._bmap, this.mapInstance._map);
      } else {
        for (let i = 0; i < this.circleLayerList.length; i++) {
          this.mapInstance._map.removeOverlay(this.circleLayerList[i]);
        }

        this.circleLayerList = [];
        this.polygonLayerList = [];
        this.labelPos = [];
      }
    },
  },
  methods: {
    getTemporaryFence() {
      let params = {};
      params.entityFactory = this.equipFactory1;
      params.entityNumber = this.equipNumber1 || "";
      fenceApi.findTemporaryFence(params).then((res) => {
        if (res.code == 0 && res.result && res.result.fenceId) {
          this.areaOption.id = res.result.fenceId;
          let BMap = this.mapInstance._bmap;
          let map = this.mapInstance._map;
          let circleLayer = new BMap.Circle(
            {
              lng: res.result.longitude,
              lat: res.result.latitude,
            },
            res.result.radius,
            { strokeColor: "blue", strokeWeight: 0.5, strokeOpacity: 0.85 }
          );
          map.addOverlay(circleLayer);
          this.circleLayerList.push(circleLayer);
          this.labelPos.push({
            lng: res.result.longitude,
            lat: res.result.latitude,
            name: res.result.fenceName,
          });

        }
      });
    },
    openAreaModal() {
      this.areaVisible = true;
    },
    areaConfim() {
      let params = {};
      params.fenceName = "临时围栏" + new Date().getTime();
      params.coordType = "bd09ll";
      params.entityFactory = this.equipFactory1;
      params.city = this.$route.query.city || "";
      params.entityNumber = this.equipNumber1 || "";
      if (this.areaOption.type == 1) {
        params.shape = 1;
        params.radius = this.areaOption.circleNum;
        params.longitude = this.center.lng;
        params.latitude = this.center.lat;
      }
      fenceApi.createTemporaryFence(params).then((res) => {
        if (res && res.code == 0) {
          this.areaOption.id = res.result;
          this.$message.success("添加成功");
          this.areaVisible = false;
          let BMap = this.mapInstance._bmap;
          let map = this.mapInstance._map;
          let circleLayer = new BMap.Circle(
            {
              lng: params.longitude,
              lat: params.latitude,
            },
            params.radius,
            { strokeColor: "blue", strokeWeight: 0.5, strokeOpacity: 0.85 }
          );
          map.addOverlay(circleLayer);
          this.circleLayerList.push(circleLayer);

          this.labelPos.push({
            lng: params.longitude,
            lat: params.latitude,
            name: params.fenceName,
          });
        }
      });
    },
    delAreaModal() {
      if (!this.areaOption.id) {
        this.$message.warning("无可删除临时围栏");
        return false;
      }
      this.$confirm("是否要执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fenceApi
          .remove({
            fenceId: this.areaOption.id,
            entityNumber: this.$route.query.equipNumber || "",
          })
          .then(() => {
            this.$message.success("删除成功");
            for (let i = 0; i < this.circleLayerList.length; i++) {
              this.mapInstance._map.removeOverlay(this.circleLayerList[i]);
            }

            this.circleLayerList = [];
            this.polygonLayerList = [];
            this.labelPos = [];
          });
      });
    },
    modelClick() {
      if (this.isInit) {
        this.showInfoWindow = !this.showInfoWindow;
      }
    },
    getVehiclePos(number, factory) {
      traceApi.getCurrentPos(number, factory).then((res) => {
        if (res.code == 0) {
          if (res.result) {
            this.equipNumber1 = number
            this.equipFactory1 = factory
            this.getTemporaryFence();
            this.isInit = true;
            this.center = {
              lng: res.result.longitude,
              lat: res.result.latitude,
            };

            this.activeFence =
              this.formateEquipType(res.result.deviceType) + "|" +
              this.equipNumber1 +
              "|" +
              this.formateEquipFactory(res.result.deviceFactory);

            // this.showInfoWindow = true;
            this.posInfo = res.result;
            let nowTime = new Date().getTime();
            if (this.posInfo.offline != 1) {
              this.timer = setInterval(() => {
                this.getLoadHisTrace(number, factory, nowTime);
                this.getCarInfo(number, factory);
              }, 30000);
            }
            this.$nextTick(() => {
              this.showInfoWindow = true;
            })
          } else {
            Message.error({
              message: "未能查询到位置",
            });
          }
        } else {
          Message.error({
            message: "实时位置获取失败！",
          });
        }
        this.$nextTick(() => {
          this.locationLoading.close();
        });
      });
    },
    getCarInfo(number, factory) {
      traceApi.getCurrentPos(number, factory).then((res) => {
        if (res.code == 0) {
          if (res.result) {
            this.center = {
              lng: res.result.longitude,
              lat: res.result.latitude,
            };


            this.posInfo = res.result;
            this.$nextTick(() => {
              this.showInfoWindow = true;
            })
          }
        }
      });
    },
    getLoadHisTrace(entityId, entityFactory, time) {
      let param = {
        entityId: entityId,
        startTime: time,
        endTime: new Date().getTime(),
        entityFactory: entityFactory,
      };
      traceApi.loadHisTrace(param).then((res) => {
        if (res.code == 0) {
          let lineList = [];
          res.result.forEach((item) => {
            lineList.push({
              lng: item.lng,
              lat: item.lat,
            });
          });
          this.posPath = lineList;
        } else {
          this.$message.error(res.msg);
          self.center = self.defaultCenter;
        }
      });
    },
    handler({ BMap, map }) {
      let self = this;
      // let mapStyle={style:'grayscale'}
      // map.setMapStyle(mapStyle);
      self.zoom = 15;
      map.enableScrollWheelZoom();
      self.mapInstance = { _bmap: BMap, _map: map };
      // if(BMap){
      //   const geolocation = new BMap.Geolocation()
      //   geolocation.getCurrentPosition(res => {
      //     const { lng, lat } = res.point
      //     // const { province, city, district, street, street_number } = res.address
      //     self.center = { lng, lat }
      //     this.$nextTick(() => {
      //       this.locationLoading.close()
      //     })
      //   })
      // }else{
      //   this.$nextTick(() => {
      //     this.locationLoading.close()
      //   })
      // }
      if (this.page1) {


        this.getVehiclePos(this.equipNumber1, this.equipFactory1);
      } else {
        this.getEquipList();
      }
    },
    getEquipList() {
      monitorApi.getEquipListInCarNumber(this.carNumber1).then((res) => {
        if (res.code == 0) {
          this.equipList = res.result;
          if (this.equipList.length > 0) {
            let equipNumber = "";
            let equipFactory = "";
            if (this.equipNumber1 && this.equipFactory1) {
              equipNumber = this.equipNumber1;
              equipFactory = this.equipFactory1;
            } else if (this.equipNumber1 && !this.equipFactory1) {
              let list = this.equipList.filter(
                (i) => i.entityNumber == this.equipNumber1
              );
              if (list.length > 0) {
                equipNumber = list[0].entityNumber;
                equipFactory = list[0].entityFactory;
              } else {
                equipNumber = this.equipList[0].entityNumber;
                equipFactory = this.equipList[0].entityFactory;
              }
            } else if (!this.equipNumber1 && this.equipFactory1) {
              let list = this.equipList.filter(
                (i) => i.entityFactory == this.equipFactory1
              );

              if (list.length > 0) {
                equipNumber = list[0].entityNumber;
                equipFactory = list[0].entityFactory;
              } else {
                equipNumber = this.equipList[0].entityNumber;
                equipFactory = this.equipList[0].entityFactory;
              }
            } else if (!this.equipNumber1 && !this.equipFactory1) {
              equipNumber = this.equipList[0].entityNumber;
              equipFactory = this.equipList[0].entityFactory;
            }
            this.activeFence = equipNumber + "|" + equipFactory;
            this.equipNumber1 = equipNumber
            this.equipFactory1 = equipFactory
            this.getVehiclePos(equipNumber, equipFactory);
          } else {
            Message.error({
              message: "未找到设备",
            });
          }
        } else {
          Message.error({
            message: res.msg,
          });
        }
        this.$nextTick(() => {
          this.locationLoading.close();
        });
      });
    },
    getFenceList(BMap, map) {
      let param = {
        // city:this.city
      };
      this.labelPos = [];
      fenceApi.findByCity(param).then((res) => {
        if (res.code == 0) {

          this.fenceList = res.result;
          for (let i = 0; i < this.fenceList.length; i++) {
            if (this.fenceList[i].shape == 1) {
              let circleLayer = new BMap.Circle(
                {
                  lng: this.fenceList[i].longitude,
                  lat: this.fenceList[i].latitude,
                },
                this.fenceList[i].radius,
                { strokeColor: "blue", strokeWeight: 0.5, strokeOpacity: 0.85 }
              );
              map.addOverlay(circleLayer);
              this.circleLayerList.push(circleLayer);
              this.labelPos.push({
                lng: this.fenceList[i].longitude,
                lat: this.fenceList[i].latitude,
                name: this.fenceList[i].fenceName,
              });
            }
            if (this.fenceList[i].shape == 2) {
              let vertexArrary = this.fenceList[i].vertexes.split(";");
              let posArrary = [];
              let _x = 0,
                _y = 0;
              for (let j = 0; j < vertexArrary.length; j++) {
                let p = vertexArrary[j].split(",");
                let pos = { lng: p[1], lat: p[0] };
                posArrary.push(pos);
                _x = _x + parseFloat(p[1]);
                _y = _y + parseFloat(p[0]);
              }

              this.polygonLayerList.push(posArrary);

              _x = _x / vertexArrary.length;
              _y = _y / vertexArrary.length;

              this.labelPos.push({
                lng: _x,
                lat: _y,
                name: this.fenceList[i].fenceName,
              });
            }
          }
        } else {
          Message.error({
            message: "未找到设备",
          });
          this.$nextTick(() => {
            this.locationLoading.close();
          });
        }
      });
    },
    timeFormate(val) {
      return formatTimeToStr(val);
    },
    selectEquip(val) {
      clearInterval(this.timer);
      let param = val.split("|");

      this.posInfo.deviceId = param[0].trim();
      this.posInfo.deviceFactory = param[1].trim();
      this.equipNumber1 = param[0].trim()
      this.equipFactory1 = param[1].trim()
      this.getVehiclePos(param[0].trim(), param[1].trim());
    },
    toTrace(macid, realPickTime, entity_factory) {
      let params = {
        equipId: macid,
        pickTime: realPickTime,
        factory: entity_factory,
      };

      const { href } = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    formateEquipFactory(v) {
      return formatFactory(v);
    },
    formateEquipType(v) {
      return equipTypeFormat(v);
    },
    signalTypeFormat(v, F) {
      return signalTypeFormat(v, F);
    },
  },
};
</script>
<style scoped>
.pos_wrap {
  width: 100%;
  height: 100%;
  background: #8c939d;
}

.map {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.w_desc {
  margin-top: 10px;
  color: #2c2c2c;
  font-weight: bold;
}

.w_item span:nth-child(1) {
  color: #2c2c2c;
  font-weight: bold;
  margin-top: 4px;
}

.pos_control_panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 4px;
  padding-right: 10px;
  box-shadow: 4px 4px 4px #8c939d;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.area_contain {}

.contain-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.j-c {
  justify-content: center;
  margin-top: 60px;
}

.item-radio {
  width: 100px;
}

.item-text {
  padding-left: 40px;
}
</style>
